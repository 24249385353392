import Axios from 'axios'
import STORAGE_KEYS from '../constants/storage_keys'
import EnvironmentUtil from '../utils/environment_util'
import StorageUtil from '../utils/storage'


let _getAxiosInstance = ({isAbsoluteUrl = false, isGql = false, hasFormData=false, withCredentials = false, reloadIfUnauthorized=true}) => {
  const headers =  {
    Accept: 'application/json',
    'Content-Type': hasFormData===true ?'multipart/form-data':'application/json',
  }
  const authToken = StorageUtil.localStorage.get(STORAGE_KEYS.AUTH_TOKEN)
  if (authToken){
    headers['Authorization'] = authToken
  }
  let axios = Axios.create({
    baseURL: isAbsoluteUrl ? '' : isGql ? EnvironmentUtil.getGQLUrl() : EnvironmentUtil.getApiUrl(),
    timeout: 30000,
    headers: headers,
    withCredentials: withCredentials,
    validateStatus: function (status) {
      return status >= 200 && status < 400
    },
  })

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error && error.response && error.response.status === 401 && reloadIfUnauthorized) {
        StorageUtil.localStorage.remove(STORAGE_KEYS.AUTH_TOKEN)
        StorageUtil.localStorage.remove(STORAGE_KEYS.AUTH_TOKEN_EXPIRY_TIME)
        StorageUtil.localStorage.remove(STORAGE_KEYS.USER_ID)
        StorageUtil.sessionStorage.clear()
        window.location.reload()
      } else {
        return Promise.reject(error)
        // todo - form some model for error
      }
    }
  )

  return axios
}

let _makeRequest = async ({url, type, data, isAbsoluteUrl, isGql,hasFormData=false, withCredentials, reloadIfUnauthorized}) => {
  let response

  try {
    if (type === 'GET') {
      response = await _getAxiosInstance({ isAbsoluteUrl,isGql, withCredentials, reloadIfUnauthorized }).get(url, { params: data })
    }
    
    if (type === 'POST') {
      response = await _getAxiosInstance({ isAbsoluteUrl, isGql,hasFormData, withCredentials, reloadIfUnauthorized }).post(url, data)
    }

    if (type === 'PUT') {
      response = await _getAxiosInstance({ isAbsoluteUrl, isGql, withCredentials, reloadIfUnauthorized }).put(url, data)
    }

    if (response.data) {
      if (response.data.success) {
        return Promise.resolve(response.data.data || response.data)
      } else {
        return _handleError(response.data.errors, response.data.error_data)
      }
    }
  } catch (error) {
    return _handleError(error)
  }
}

let _handleError = (error, errorData) => {
  return Promise.resolve({
    error,
    errorData
    // todo - form some model for error
  })
}

let NetworkService = {
  get: ({url, data, isAbsoluteUrl, isGql, withCredentials, reloadIfUnauthorized}) => {
    return _makeRequest({url, type: 'GET', data, isAbsoluteUrl, isGql, withCredentials, reloadIfUnauthorized})
  },
  post: ({url, data, isAbsoluteUrl, isGql,hasFormData=false, withCredentials, reloadIfUnauthorized}) => {
    return _makeRequest({url, type: 'POST', data, isAbsoluteUrl, isGql,hasFormData, withCredentials, reloadIfUnauthorized})
  },
  put: ({url, data, isAbsoluteUrl, isGql, withCredentials, reloadIfUnauthorized}) => {
    return _makeRequest({url, type: 'PUT', data, isAbsoluteUrl, isGql, withCredentials, reloadIfUnauthorized})
  }
}

export default NetworkService
