
let initialState = {
}

let userDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return { ...state }
  }
}

export default userDetailsReducer
