import React from 'react'
import { connect } from 'react-redux'
import UserActions from '../user/user_actions'
import { useEffect, useState } from 'react'
import { isAuthTokenNotPresent, isAuthTokenValid } from '../../utils/common_util'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../constants/routes'
import { useSearchParams } from 'react-router-dom'

const RouteHoc = ({ childComponent, isAuthRequired, getAuthToken, saveDeviceId }) => {

  const [ isFetchingAccessToken, setIsFetchingAccessToken ] = useState(true)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const _deviceIdInParams = searchParams.get('device_id')

  useEffect(() => {  
    if (isAuthTokenValid()) {
      _onAuthentication()
    } else {
      console.log('Access token has expired. Refreshing...')
      getAuthToken({
        onDone: ({isSuccessful}) => {
          if (isSuccessful) {
            _onAuthentication()
          } else {
            if (isAuthRequired) {
              let { pathname, search } = window.location,
               _redirectPath = pathname + search
              _redirectPath = encodeURIComponent(_redirectPath)
              navigate(`${ROUTES.LOGIN}?redirectPath=${_redirectPath}`) 
              // redirectPath is being passed to Login component so that after login, it can take the user to the place that the user originally landed on
            } else {
              setIsFetchingAccessToken(false)
            }
          }
        },
      })
    }
  }, [])

  let _onAuthentication = () => {
    if (_deviceIdInParams) {
      saveDeviceId({
        deviceId: _deviceIdInParams,
        onDone: ({isSuccessful}) => {
          if (isSuccessful) {
            setIsFetchingAccessToken(false)
          }
        }
      })
    } else {
      setIsFetchingAccessToken(false)
    }
  }

  return isFetchingAccessToken ? <></> : childComponent
  
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAuthToken: payload => {
      dispatch(UserActions.getAuthToken(payload))
    },
    saveDeviceId: payload => {
      dispatch(UserActions.saveDeviceId(payload))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteHoc)