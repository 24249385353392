import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import { FormattedMessage } from 'react-intl'

import DownArrow from '../../images/ic_down_arrow'
import UpArrow from '../../images/ic_up_arrow'

import './index.scss'

const Accordian = ({ title, content, isOpenInitially }) => {

  const [ isOpen, setIsOpen ] = useState(isOpenInitially)

  return (
    <Collapsible
      trigger={
        <div className="accordian">
          <div className="accordian-header">
            <FormattedMessage id={title} />
          </div>
          <div className="accordian-arrow">{isOpen ? <UpArrow /> : <DownArrow />}</div>
        </div>
      }
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      isOpen={isOpenInitially}
    >
      <FormattedMessage id={content} />
    </Collapsible>
  )
}

export default Accordian
