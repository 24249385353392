import STORAGE_KEYS from '../../constants/storage_keys.js'
import StorageUtil from '../../utils/storage.js'
import UserRepository from './user_repository.js'

const UserActions = {

  getAuthToken: payload => {
    let { onDone } = payload || {}
    return async dispatch => {
      try {
        let response = await UserRepository.getAuthToken()

        if (response && response.error) {
          if (onDone) onDone({ error: response.error })
        } else {
          let { token, token_expiry } = response || {}

          StorageUtil.localStorage.set(STORAGE_KEYS.AUTH_TOKEN, token)
          StorageUtil.localStorage.set(STORAGE_KEYS.AUTH_TOKEN_EXPIRY_TIME, token_expiry)

          if (onDone) onDone({ isSuccessful: true })
        }
      } catch (e) {
        if (onDone) onDone({ error: e })
      }
    }
  },

  // not in use currently, logout option is not there in the application
  // revokeAuthToken: payload => {
  //   let { onSuccess, onError } = payload || {}
  //   return async dispatch => {
  //     try {
  //       let response = await UserRepository.revokeAuthToken()

  //       if (response && response.error) {
  //         if (onError) onError()
  //       } else {
  //         if (onSuccess) onSuccess()
  //       }
  //     } catch (e) {
  //       if (onError) onError()
  //     }
  //   }
  // },

  saveDeviceId: (payload) => {
    let { deviceId, onDone } = payload || {}
    return async dispatch => {
      try {
        let response = await UserRepository.saveDeviceId(deviceId)

        if (response && response.error) {
          if (onDone) onDone({ error: response.error })
        } else {
          if (onDone) onDone({ isSuccessful: true })
        }
      } catch (e) {
        if (onDone) onDone({ error: e })
      }
    }
  },
}

export default UserActions