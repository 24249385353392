import StorageUtil from "./storage"
import STORAGE_KEYS from "../constants/storage_keys"

export const isAuthTokenNotPresent = () => {
  let token = StorageUtil.localStorage.get(STORAGE_KEYS.AUTH_TOKEN),
    tokenExpiryTime = StorageUtil.localStorage.get(STORAGE_KEYS.AUTH_TOKEN_EXPIRY_TIME)

  if (!(token && tokenExpiryTime)) return true

  return false
}

export const isAuthTokenExpired = () => {
    let token = StorageUtil.localStorage.get(STORAGE_KEYS.AUTH_TOKEN),
      tokenExpiryTime = StorageUtil.localStorage.get(STORAGE_KEYS.AUTH_TOKEN_EXPIRY_TIME),
      _expiryTime = new Date(tokenExpiryTime).getTime(),
      _currentTime = Date.now()

    if (_expiryTime <= _currentTime) return true
  
    return false
  }

export const isAuthTokenValid = () => {
  return !isAuthTokenNotPresent() && !isAuthTokenExpired()
}