import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import './login.scss'
import { isAuthTokenValid } from '../../utils/common_util'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../constants/routes'
import Button, { ButtonType } from '../../components/button'
import { useSearchParams } from 'react-router-dom'
import EnvironmentUtil from '../../utils/environment_util'
import { LOGIN_TYPES, TERMS_OF_USE_URL, PRIVACY_POLICY_URL } from '../../constants/constants'
import DiscordLogo from '../../images/ic_discord'
import FacebookLogo from '../../images/ic_facebook'
import GoogleLogo from '../../images/ic_google'
import RewardsProgramLogo from '../../images/ic_rewards_program'
import RedirectionArrow from '../../images/ic_redirection_arrow'

function Login(props) {
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthTokenValid()) {
      navigate(ROUTES.REWARDS_HOME)
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta name={'robots'} content={'noindex'} />
      </Helmet>
      <div id={'login'}>
        <div className={'container flex-center-center'}>
          <div className='login-box'>
            <div className={'content-area'}>
              <div className='title'>
                <FormattedMessage id="global.title"/>
              </div>
              <div className='sub-title'>
                <FormattedMessage id="login.subTitle"/>
              </div>
              <div className='login-options'>
                <_LoginButton type={LOGIN_TYPES.GOOGLE}/>
                <_LoginButton type={LOGIN_TYPES.DISCORD}/>
                <_LoginButton type={LOGIN_TYPES.FACEBOOK}/>
              </div>
              <div className='tnc'>
                <FormattedMessage id="login.termsAndConditionsPrefix"/>
                &nbsp;
                <a href={TERMS_OF_USE_URL}>
                  <FormattedMessage id="login.termsOfUse"/>
                </a>
                &nbsp;
                  <FormattedMessage id="global.and"/>
                &nbsp;
                <a href={PRIVACY_POLICY_URL}>
                  <FormattedMessage id="login.privacyPolicy"/>
                </a>
                &#44;&nbsp;
                <FormattedMessage id="login.termsAndConditionsPostfix"/>
              </div>
            </div>
            <div className='learn-more'>
              <a href={window.location.origin + ROUTES.REWARDS_HOME}>
                <RewardsProgramLogo className={'rewards-logo'}/>
                <FormattedMessage id="login.learnAboutRewards"/>
                <RedirectionArrow className='redirection-arrow'/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const _LoginButton = (props) => {
  const [searchParams] = useSearchParams()
  const _deviceIdInParams = searchParams.get('device_id')
  const _redirectionPathInParams = searchParams.get('redirectPath') || ''
  const { type } = props
  
  const _getButtonConfig = () => {
    let icon, title
  
    switch (type) {
      case LOGIN_TYPES.DISCORD:
        icon = <DiscordLogo/>
        title = <FormattedMessage id="login.loginType" values={{ login_type: 'Discord' }}/>
        break
      case LOGIN_TYPES.FACEBOOK:
        icon = <FacebookLogo/>
        title = <FormattedMessage id="login.loginType" values={{ login_type: 'Facebook' }}/>
        break
      case LOGIN_TYPES.GOOGLE:
        icon = <GoogleLogo/>
        title = <FormattedMessage id="login.loginType" values={{ login_type: 'Google' }}/>
        break
  
      default:
        break
    }

    return { icon, title }
  }

  const _buttonConfig = _getButtonConfig()
  const { icon, title } = _buttonConfig
  
  const _onClick = () => {
     let _redirectUri = ROUTES.REWARDS_HOME

    if (_redirectionPathInParams) {
      _redirectUri = _redirectionPathInParams
    } else if (_deviceIdInParams) {
      _redirectUri = `${ROUTES.DEVICE_CONNECTION_SUCCESS}?device_id=${_deviceIdInParams}`
    }

    _redirectUri = `${window.location.origin}${_redirectUri}`

    let _ssoURL = `${EnvironmentUtil.getNowGGAuthUrl()}/accounts/auth/v1/identifier?provider=${type}&continue=${_redirectUri}`
    window.location = _ssoURL 
  }
  
  return (
    <Button onClick={_onClick} buttonType={ButtonType.secondary} className={'flex-center-center'}>
      <>
        <span className='login-icon'>{icon}</span>
        <div className={`login-text`}>{title}</div>
      </>
    </Button>
  )
}

export default injectIntl(Login)
