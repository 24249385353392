import React from 'react'

const RewardsProgramLogo = (props) => {
  let { className = '' } = props
  return (
    <svg className={className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#544BC9"/>
        <path d="M13.9999 27.9998C21.7319 27.9998 27.9999 21.7318 27.9999 13.9998C27.9999 8.14467 24.4056 3.12903 19.303 1.03906L16.4932 7.13206L17.6819 9.58377L16.4932 18.7962L11.2555 19.242L6.52588 25.84C8.68847 27.208 11.2517 27.9998 13.9999 27.9998Z" fill="#3F32A5"/>
        <path d="M9.25869 6.79875H14.3382L19.303 1.04102L18.4906 6.79875L19.5455 7.85362L20.6003 8.90848L20.1073 12.3536L18.704 13.7471L19.7589 14.802L19.0912 19.4564L16.9047 20.6753H11.307L6.53784 25.8162L8.91111 8.81149L9.25869 6.79875ZM12.0078 15.5465L11.7952 17.0564H15.3753L15.5879 15.5465H12.0078ZM12.7434 10.4176L12.5308 11.9276H16.1109L16.3235 10.4176H12.7434Z" fill="white"/>
    </svg>
  )
}

export default RewardsProgramLogo
