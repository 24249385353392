const EnvironmentUtil = {
  getNowGGAuthUrl: () => {
    return process.env.REACT_APP_NOWGG_AUTH_URL
  },
  getApiUrl: () => {
    return process.env.REACT_APP_API_URL
  },
  getGQLUrl: () => {
    return process.env.REACT_APP_GQL_BASE_URL
  },
  isProd: () => {
    return process.env.REACT_APP_ENV === 'prod'
  },
  isLocal: () => {
    return process.env.REACT_APP_ENV === 'local'
  }
}

export default EnvironmentUtil