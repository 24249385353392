import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import './device_connection_success.scss'
import Button, { ButtonType } from '../../components/button'
import DummyImage from '../../images/dummy_image.jpeg'

function DeviceConnectionSuccess(props) {

  const _redirectToBST = () => {
    //todo
  }
  
  return (
    <>
      <Helmet>
        <meta name={'robots'} content={'noindex'} />
      </Helmet>
      <div id={'deviceConnectionSuccess'}>
        <div className={'container flex-center-center'}>
          <div className={'content-area'}>
            <div className='title'>
              <FormattedMessage id='global.congratulations'/>
            </div>
            {/* todo replace the illustration with the actual image, when available from design */}
            <img src={DummyImage} className='illustration'/>
            <div className='note'>
              <FormattedMessage id='deviceConnectionSuccess.message'/>
            </div>
            <Button onClick={_redirectToBST} buttonType={ButtonType.primary} className={'flex-center-center'}>
              <FormattedMessage id='deviceConnectionSuccess.returnToBluestacks'/>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}


export default injectIntl(DeviceConnectionSuccess)
