import React from 'react'

export default function GoogleLogo() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.65404 14.1913L5.92306 16.9201L3.25135 16.9767C2.45289 15.4957 2 13.8013 2 12.0007C2 10.2596 2.42345 8.61763 3.17403 7.17188H3.17461L5.55318 7.60795L6.59514 9.97226C6.37706 10.608 6.2582 11.2905 6.2582 12.0007C6.25828 12.7715 6.3979 13.51 6.65404 14.1913Z" fill="#FBBB00"/>
        <path d="M22.8164 10.0391C22.937 10.6742 22.9999 11.3302 22.9999 12.0006C22.9999 12.7523 22.9208 13.4856 22.7703 14.1929C22.2591 16.5998 20.9235 18.7016 19.0734 20.1889L19.0728 20.1883L16.0768 20.0354L15.6528 17.3885C16.8805 16.6685 17.8399 15.5418 18.3453 14.1929H12.7307V10.0391H18.4272H22.8164Z" fill="#518EF8"/>
        <path d="M19.073 20.1885L19.0736 20.1891C17.2742 21.6354 14.9884 22.5008 12.5001 22.5008C8.50146 22.5008 5.02494 20.2658 3.25146 16.9768L6.65416 14.1914C7.54088 16.5579 9.82377 18.2426 12.5001 18.2426C13.6505 18.2426 14.7282 17.9316 15.653 17.3887L19.073 20.1885Z" fill="#28B446"/>
        <path d="M19.2022 3.9173L15.8006 6.70209C14.8435 6.10384 13.7121 5.75824 12.5 5.75824C9.76311 5.75824 7.43752 7.52015 6.59523 9.97153L3.17465 7.17115H3.17407C4.92158 3.80192 8.44195 1.5 12.5 1.5C15.0477 1.5 17.3837 2.40751 19.2022 3.9173Z" fill="#F14336"/>
    </svg>
  )
}