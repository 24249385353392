import { API_ENDPOINTS } from '../../constants/api_endpoints'
import NetworkService from '../../services/network'
import EnvironmentUtil from '../../utils/environment_util'

const UserRepository = {
  getAuthToken: async _ => {
    let response = await NetworkService.get({
      url: EnvironmentUtil.getNowGGAuthUrl() + API_ENDPOINTS.GET_AUTH_TOKEN,
      isAbsoluteUrl: true,
      withCredentials: true,
      reloadIfUnauthorized: false
    })
    return response
  },

  // not in use currently, logout option is not there in the application
  // revokeAuthToken: async _ => {
  //   let response = await NetworkService.get({
  //     url: EnvironmentUtil.getNowGGAuthUrl() + API_ENDPOINTS.REVOKE_AUTH_TOKEN,
  //     isAbsoluteUrl: true,
  //     withCredentials: true
  //   })
  //   return response
  // },

  saveDeviceId:  async deviceId => {
    let response = await NetworkService.put({
      url: API_ENDPOINTS.SAVE_DEVICE_ID,
      data: {
        reward_ids: [deviceId]
      }
    })
    return response
  },
}

export default UserRepository