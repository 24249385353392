import React from 'react'

const RedirectionArrow = (props) => {
  let { className = '' } = props
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.83325 14.1673L13.4999 6.50065M14.1666 14.1673V5.83398H5.83325" stroke="#5029B7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default RedirectionArrow
