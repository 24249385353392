export const stringToCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export function convertToDoubleDigits(number) {
  if (number < 10) {
    return `0${number}`
  }
  return number
}

export const formatFollowersCount = (count, decPlaces = 1) => {
  decPlaces = Math.pow(10, decPlaces)
  
  // Enumerate number abbreviations
  let abbrev = ['k', 'm', 'b', 't']
  
  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    let size = Math.pow(10, (i + 1) * 3)
  
    // If the number is bigger or equal do the abbreviation
    if (size <= count) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      count = Math.round((count * decPlaces) / size) / decPlaces
  
      // Handle special case where we round up to the next abbreviation
      if (count === 1000 && i < abbrev.length - 1) {
        count = 1
        i++
      }
  
      // Add the letter for the abbreviation
      count += abbrev[i]
  
      // We are done... stop
      break
    }
  }
  
  return count
}

export function inDoubleDigits(number) {
  if (number < 10) {
    return `0${number}`
  }
  return number
}