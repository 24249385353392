import { connect } from 'react-redux'
import LoginActions from './actions'
import Login from './login'

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)