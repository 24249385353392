import React from 'react'
import { injectIntl } from 'react-intl'
import LoadingOverlay from 'react-loading-overlay'
import './index.scss'

const FullScreenLoader = ({ children, text, intl }) => {
  if (!text) {
    text = intl.formatMessage({ id: 'global.loading' })
  }
  return (
    <LoadingOverlay
      active={true}
      spinner
      text={text}
      className="full-screen-loader"
    >
      {children}
    </LoadingOverlay>
  )
}

export default injectIntl(FullScreenLoader)
