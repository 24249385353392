import React from 'react'

export default function DiscordLogo() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1153_14369)">
            <path d="M20.3303 4.52336C18.7535 3.80147 17.0888 3.28932 15.3789 3C15.1453 3.41848 14.9336 3.84882 14.7446 4.28929C12.9232 4.01573 11.0711 4.01573 9.24961 4.28929C9.06068 3.84882 8.84898 3.41848 8.61535 3C6.90454 3.29264 5.23893 3.80597 3.66019 4.52713C0.526644 9.16327 -0.322811 13.6843 0.101917 18.1411C1.9368 19.4972 3.99084 20.5284 6.17458 21.1897C6.6661 20.5283 7.10117 19.8267 7.47519 19.0925C6.76507 18.8274 6.0797 18.5002 5.42706 18.1146C5.59884 17.9901 5.76684 17.8617 5.92918 17.7371C7.82837 18.6303 9.90124 19.0933 12 19.0933C14.0987 19.0933 16.1715 18.6303 18.0707 17.7371C18.235 17.8711 18.403 17.9995 18.5729 18.1146C17.9191 18.5011 17.2325 18.829 16.5209 19.0944C16.8943 19.8284 17.3294 20.5294 17.8216 21.1897C20.0072 20.5311 22.0628 19.5004 23.898 18.143C24.3963 12.9745 23.0467 8.49504 20.3303 4.52336ZM8.01318 15.4002C6.82961 15.4002 5.85179 14.3261 5.85179 13.0047C5.85179 11.6833 6.79563 10.5998 8.0094 10.5998C9.22318 10.5998 10.1934 11.6833 10.1727 13.0047C10.1519 14.3261 9.21941 15.4002 8.01318 15.4002ZM15.9867 15.4002C14.8013 15.4002 13.8272 14.3261 13.8272 13.0047C13.8272 11.6833 14.7711 10.5998 15.9867 10.5998C17.2024 10.5998 18.1651 11.6833 18.1444 13.0047C18.1236 14.3261 17.193 15.4002 15.9867 15.4002Z" fill="#4565AD"/>
        </g>
        <defs>
            <clipPath id="clip0_1153_14369">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  )
}