import React from 'react'
import './index.scss'

export const ButtonType = {
  primary: 'primary',
  secondary: 'secondary',
}

const Button = ({ buttonType = '', children, className = '', onClick, disabled = false }) => {
  return (
    <button className={`button ${buttonType} ${className}`} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}

export default Button
