import React from 'react'
import { Component } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import RouteHoc from './global_modules/route_hoc/route_hoc'
import { ROUTES } from './constants/routes'
import Login from './screens/login/index'
import RewardsHome from './screens/rewards_home/index'
import DeviceConnectionSuccess from './screens/device_connection_success/index'

class RouteWrapper extends Component {
  render() {
    return (
      <Routes>
        <Route
          exact
          path={ROUTES.LOGIN}
          element={<Login />}
        />
        <Route
          exact
          path={ROUTES.REWARDS_HOME}
          element={<RouteHoc childComponent={<RewardsHome />} isAuthRequired={false} />}
        />
        <Route
          exact
          path={ROUTES.DEVICE_CONNECTION_SUCCESS}
          element={<RouteHoc childComponent={<DeviceConnectionSuccess />} isAuthRequired={true} />}
        />
        <Route path={'*'} element={<Navigate to={ROUTES.REWARDS_HOME} />} />
      </Routes>
    )
  }
}

export default RouteWrapper
