import React from "react";
import { Helmet } from "react-helmet";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import Accordian from "../../components/accordian";
import Button, { ButtonType } from "../../components/button";
import "./rewards_home.scss";
import DummyImage from "../../images/dummy_image.jpeg";
import Step1Illustration from "../../images/illustration_step1_howItWorks.png";
import Step2Illustration from "../../images/illustration_step2_howItWorks.png";
import Step3Illustration from "../../images/illustration_step3_howItWorks.png";
import Benefit1Illustration from "../../images/illustration_benefit1.png";
import Benefit2Illustration from "../../images/illustration_benefit2.png";
import Benefit3Illustration from "../../images/illustration_benefit3.png";
import Benefit4Illustration from "../../images/illustration_benefit4.png";
import RightArrow from "../../images/ic_right_arrow";
import { inDoubleDigits } from "../../utils/formatting_util";
import { SUPPORT_EMAIL } from "../../constants/constants";

function RewardsHome(props) {
  return (
    <>
      <Helmet>
        <meta name={"robots"} content={"noindex"} />
      </Helmet>
      <div id={"rewardsHome"}>
        <_Introduction />
        <_HowToEarn />
        <_HowItWorks {...props} />
        <_Benefits {...props} />
        <_FAQs {...props} />
      </div>
    </>
  );
}

const _Introduction = () => {
  const _scrollToBenefits = () => {
    document
      .getElementsByClassName("benefits")[0]
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="introduction-bg-container">
      <div className="left-pane"></div>
      <div className="introduction flex-space-between-center">
        <div className="content">
          <div className="pre-heading">
            <FormattedMessage id="rewardsInfo.playAndEarn" />
          </div>
          <div className="heading">
            <FormattedMessage id="global.title" />
          </div>
          <div className="post-heading">
            <FormattedMessage id="rewardsInfo.introductionText" />
          </div>
          <Button onClick={_scrollToBenefits} classType={ButtonType.primary}>
            <FormattedMessage id="rewardsInfo.exploreMore" />
          </Button>
        </div>
        <div className="illustration">
          {/* todo replace this illustration when available from design */}
          <img src={DummyImage} />
        </div>
      </div>
    </div>
  );
};

const _HowToEarn = () => {
  let highlight1 = (
    <span className="highlight">
      {<FormattedMessage id="rewardsInfo.howToEarn.description.highlight1" />}
    </span>
  );
  let highlight2 = (
    <span className="highlight">
      {<FormattedMessage id="rewardsInfo.howToEarn.description.highlight2" />}
    </span>
  );
  return (
    <div className="how-to-earn">
      <div className="content-container">
        <div className="left-content">
          <FormattedMessage id="rewardsInfo.howToEarn.title" />
        </div>
        <div className="right-content">
          <FormattedMessage
            id="rewardsInfo.howToEarn.description"
            values={{ highlight1, highlight2 }}
          />
        </div>
      </div>
    </div>
  );
};

const _HowItWorks = (props) => {
  let { intl } = props;
  const _Step = (step) => {
    let { index, title, subtitle, illustration } = step;

    return (
      <div className="step">
        <div className="step-content">
          <div className="arrow">
            <RightArrow />
          </div>
          <div className="step-text">
            <div className="index">{`${intl.formatMessage({
              id: "global.step",
            })} - ${inDoubleDigits(index)}.`}</div>
            <div className="step-title">{title}</div>
            <div className="step-subtitle">{subtitle}</div>
          </div>
        </div>
        <div className="step-illustration">
          <img src={illustration} />
        </div>
      </div>
    );
  };

  return (
    <div className="how-it-works-bg-container">
      <div className="how-it-works">
        <div className="heading">
          <FormattedMessage id="rewardsInfo.howItWorks.title" />
        </div>
        <div className="steps-container">
          <_Step
            index={1}
            title={intl.formatMessage({
              id: "rewardsInfo.howItWorks.step1.title",
            })}
            subtitle={intl.formatMessage({
              id: "rewardsInfo.howItWorks.step1.description",
            })}
            illustration={Step1Illustration}
          />
          <_Step
            index={2}
            title={intl.formatMessage({
              id: "rewardsInfo.howItWorks.step2.title",
            })}
            subtitle={intl.formatMessage({
              id: "rewardsInfo.howItWorks.step2.description",
            })}
            illustration={Step2Illustration}
          />
          <_Step
            index={3}
            title={intl.formatMessage({
              id: "rewardsInfo.howItWorks.step3.title",
            })}
            subtitle={intl.formatMessage({
              id: "rewardsInfo.howItWorks.step3.description",
            })}
            illustration={Step3Illustration}
          />
        </div>
      </div>
    </div>
  );
};

const _Benefits = (props) => {
  let { intl } = props;
  const _BenefitCard = (benefit) => {
    let { index, title, subtitle, illustration } = benefit;

    return (
      <div className="benefit-card">
        <div className="illustration-container">
          <img src={illustration} />
          {/* <div className='index'>{`${index}.`}</div> */}
        </div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
    );
  };

  return (
    <div className="benefits-bg-container">
      <div className="benefits">
        <div className="heading">
          <FormattedMessage id="rewardsInfo.benefits.title" />
        </div>
        <div className="post-heading">
          &#40;
          <FormattedMessage id="global.comingSoon" />
          &#41;
        </div>
        <div className="benefit-cards">
          <_BenefitCard
            index={1}
            title={intl.formatMessage({ id: "rewardsInfo.benefit1.title" })}
            subtitle={intl.formatMessage({
              id: "rewardsInfo.benefit1.description",
            })}
            illustration={Benefit1Illustration}
          />
          <_BenefitCard
            index={2}
            title={intl.formatMessage({ id: "rewardsInfo.benefit2.title" })}
            subtitle={intl.formatMessage({
              id: "rewardsInfo.benefit2.description",
            })}
            illustration={Benefit2Illustration}
          />
          <_BenefitCard
            index={3}
            title={intl.formatMessage({ id: "rewardsInfo.benefit3.title" })}
            subtitle={intl.formatMessage({
              id: "rewardsInfo.benefit3.description",
            })}
            illustration={Benefit3Illustration}
          />
          <_BenefitCard
            index={4}
            title={intl.formatMessage({ id: "rewardsInfo.benefit4.title" })}
            subtitle={intl.formatMessage({
              id: "rewardsInfo.benefit4.description",
            })}
            illustration={Benefit4Illustration}
          />
        </div>
        <div className="note">
          <FormattedMessage id="rewardsInfo.benefits.manyMoreComingSoon" />
        </div>
      </div>
    </div>
  );
};

const _FAQs = (props) => {
  let { intl } = props;
  const faqs = [
    //todo: update the strings for all the answers
    {
      question: intl.formatMessage({ id: "rewardsInfo.faqs.question1" }),
      answer: intl.formatMessage({ id: "rewardsInfo.faqs.answer1" }),
    },
    {
      question: intl.formatMessage({ id: "rewardsInfo.faqs.question2" }),
      answer: intl.formatMessage({ id: "rewardsInfo.faqs.answer2" }),
    },
    {
      question: intl.formatMessage({ id: "rewardsInfo.faqs.question3" }),
      answer: intl.formatMessage({ id: "rewardsInfo.faqs.answer3" }),
    },
    {
      question: intl.formatMessage({ id: "rewardsInfo.faqs.question4" }),
      answer: intl.formatMessage({ id: "rewardsInfo.faqs.answer4" }),
    },
    {
      question: intl.formatMessage({ id: "rewardsInfo.faqs.question5" }),
      answer: intl.formatMessage({ id: "rewardsInfo.faqs.answer5" }),
    },
    {
      question: intl.formatMessage({ id: "rewardsInfo.faqs.question6" }),
      answer: intl.formatMessage({ id: "rewardsInfo.faqs.answer6" }),
    },
  ];

  const _renderFaqs = () => {
    return faqs.map((f, i) => {
      let { question, answer } = f;

      return <Accordian key={`faq-${i}`} title={question} content={answer} />;
    });
  };

  return (
    <div className="faqs-bg-container">
      <div className="faqs flex-center-center">
        <div className="heading">
          <FormattedMessage id="rewardsInfo.faqs.title" />
        </div>
        <div className="post-heading">
          <FormattedMessage
            id="rewardsInfo.faqs.description"
            values={{
              email: <a href={`mailTo:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>,
            }}
          />
        </div>
        <div className="faqs-collapsibles">{_renderFaqs()}</div>
      </div>
    </div>
  );
};

export default injectIntl(RewardsHome);
