import React, { useEffect } from 'react'
import RoundAlertIconWithWhiteBg from '../../images/ic_alert_round_white_bg'
import TriangularAlertIcon from '../../images/ic_triangular_alert_with_white_bg'
import './index.scss'

export const SnackbarTypes = {
  error: 'error',
  success: 'success',
  info: 'info'
}

const Snackbar = ({
  type = SnackbarTypes.success,
  duration = 5000,
  children,
  hideSnackbar,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      hideSnackbar()
    }, duration)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className="snackbar-container">
      <div className={`snackbar ${type}`}>
        {type === SnackbarTypes.success ? <RoundAlertIconWithWhiteBg /> : <TriangularAlertIcon />}
        {children}
      </div>
    </div>
  )
}

export default Snackbar
